<template>
<div>
  <div class="start-trigger-label" @click="onItemClick">
    <i :class="trigger.icon"></i> {{ trigger.label }}
  </div>

  <vuestic-modal
    :isOpen="isShowModal"
    @cancel="closeModal"
    :okShown="false"
    :cancelShown="false"
    :force="true"
    cancelClass="btn btn-danger"
  >
    <span slot="title" class="text-primary font-weight-bold">Missed Call ( Connect Call )</span>
    <ValidationObserver 
      v-slot="{ handleSubmit, invalid, dirty }"
      ref="newCallTriggerForm"
    >
      <form @submit.prevent="handleSubmit(save)" class="newCallTriggerForm">
        <div class="row">
          <div class="col-md-12 mb-4">
            <label class="input-label">Installs <a v-b-tooltip.hover title="Only installs with connect call feature enabled are available for this trigger." class="fa fa-question-circle tooltip-icon" /></label>
            <multiselect
              :show-labels="false" 
              v-model="installSelected"
              :options="installs"
              :multiple="true"
              :preserve-search="true"
              placeholder="Select Install"
              label="label"
              track-by="id"
              @input="onSelectChange"
            >
              <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="installSelected && installSelected.length & !isOpen">{{ installSelected.length }} options selected</span></template>
            </multiselect>
            <ul v-if="installSelected" class="selectedInstallWrapper">
              <li v-for="item in installSelected" :key="item.id">{{ item.label }}</li>
            </ul>
          </div>
          <div class="col-md-12 mb-4">
            <div class="custom-checkbox-wrapper d-inline-block">
              <label class="checkbox-label">
                <input type="checkbox" v-model="automation.options.stop_on_reply">
                <span class="checkbox-custom"></span>
              </label>
            </div>
            <span class="d-inline-block ml-2" style="cursor: pointer" @click="automation.options.stop_on_reply = !automation.options.stop_on_reply">Stop on Contact Reply <a v-b-tooltip.hover title="When someone replies to a message in the automation the remainder of the messages will be cancelled" class="fa fa-question-circle tooltip-icon" /></span>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-12 d-flex justify-content-center">
            <button class="btn btn-primary" :disabled="invalid || processing" style="min-width: 80px;">
              <atom-spinner v-if="processing" slot="loading" :animation-duration="1500" :size="14" color="#FFF" style="margin: auto;"/>
              <span v-else>Save</span>
            </button>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </vuestic-modal>
</div>
</template>

<script>
import Vue from 'vue';
import { parsePhoneNumberFromString } from 'libphonenumber-js'

export default {
  components: {
  },

  props: {
    automation: {
      type: Object,
      default: null,
    },
    blocks: {
      type: Array,
      default: null,
    },
    trigger: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      processing: false,
      isShowModal: false,
      installSelected: [],
      loading: false,
      isOpenModalTrigger: false,
      installs: [],
    }
  },

  mounted() {
    this.automation.options = {
      ...{ stop_on_reply: false },
      ...this.automation.options
    }
  },

  watch: {
    'automation.installs': {
      handler: function(newValue) {
        if (newValue)
        {
          this.initValues();
        }
      },
      deep: true,
    },
  },

  computed: {
    installList() {
      if (this.installSelected)
        return this.installSelected.map(item => item.label).join(', ');
      return ''
    }
  },

  created() {
    this.installSelected = this.automation.installs
  },

  methods: {
    initValues() {
      this.installSelected = this.installs.filter(item => {
        return this.automation.installs.find(item1 => item1.id == item.id)
      })
    },

    onSelectChange() {
      this.automation.installs = this.installSelected
    },

    onItemClick() {
      this.showModal();
    },

    save() {
      if (!this.automation.installs || !this.automation.installs.length)
      {
        Vue.$toast.open({
          message: 'Installs can\'t be empty',
          type: 'error',
          duration: 3000,
        })
        return false;
      }
      this.installSelected = this.automation.installs
      this.isShowModal = false
    },

    showModal() {
      this.loadInstalls();
      this.isShowModal = true
    },
    
    closeModal() {
      this.isShowModal = false
    },

    loadInstalls() {
      this.loading = false
      this.$store.dispatch('install/fetchAllInstalls').then((res) => {
        this.loading = false
        let tmp = res.data.filter(item => item.status == 'active' && item.active)
        this.installs = tmp.map(item => {
          const phoneNumber = parsePhoneNumberFromString(item.available_number ? item.available_number: '')
          return {
            id: item.id,
            label:( phoneNumber ? phoneNumber.formatNational() + ' : ' : '' ) + item.website
          }
        })
        this.initValues();
      });
    },
  },
}
</script>

<style lang="scss" scoped>
  .start-trigger-label {
    color: #fff;
    text-align: center;
    padding: 16px 12px;
    cursor: pointer;
    user-select: none;
  }
  .selectedInstallWrapper {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-top: 10px;
    li {
      padding: 3px 10px;
      color: #ffffff;
      background-color: $rb-blue;
      border-radius: 10px;
      margin: 5px;
      font-weight: bold;
    }
  }
  .custom-checkbox-wrapper .checkbox-label {
    --primaryColor: #3578c6;
  }
  .custom-checkbox-wrapper .checkbox-label .checkbox-custom {
    border-color: $text-gray;
    top: 4px;
    height: 17px;
    width: 17px;
  }

  .custom-checkbox-wrapper .checkbox-label input:checked ~ .checkbox-custom::after {
    left: 5px;
    top: 0px;
  }
  .tooltip-icon {
    font-size: 16px;
  }
</style>