<template>
  <div class="action-block">
    <div class="block-item">
      <div class="block-content">
        <div class="start-action" :class="{ 'trigger-action': automation.start_trigger }">
          <div v-if="automation.start_trigger && triggerById(automation.start_trigger)" class="start-action-wrapper">
            <component :is="triggerById(automation.start_trigger).component" :automation="automation" :trigger="triggerById(automation.start_trigger)" :type="type" ref="startTriggerComponent"></component>
          </div>
          <div v-else class="start-action-title" @click="showTriggerModal">
            Add a start trigger
          </div>
          <div class="block-controls" style="right: 0px;cursor: pointer;" @click="deleteStartTrigger">
            <span class="btn-action" title="Delete">
              <i class="text-danger fa fa-trash"></i>
            </span>
          </div>
        </div>
      </div>
    </div>

    <vuestic-modal
      :isOpen="isShowTriggerModal"
      @cancel="closeModalTrigger"
      cancelText="Back"
      :okShown="false"
      :cancelShown="false"
      :force="true"
      cancelClass="btn btn-danger"
      class="new-trigger-modal"
    >
      <span slot="title" class="text-primary font-weight-bold">Add a Start Trigger</span>
      <div class="triggers-section">
        <div class="list-groups">
          <div v-for="(item, key) in groups" :key="key" :class="{ 'active': selectedGroup && item.id === selectedGroup.id }" class="group-item" @click="selectGroup(item)">
            {{ item.label }} <i class="fa fa-angle-right"></i>
          </div>
        </div>
        <div class="list-triggers">
          <div v-for="(trigger, key1) in selectedTriggers" :key="key1" class="trigger-item" @click="selectTrigger(trigger)">
            <div class="icon">
              <i :class="trigger.icon"></i>
            </div>
            <div class="desc">
              <div class="name">
                {{ trigger.label }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </vuestic-modal>
  </div>
</template>

<script>
import DirectCall from './Triggers/DirectCall'
import SmsKeyword from './Triggers/SmsKeyword'
import HomeAdvisor from './Triggers/HomeAdvisor'
import MissedCall from './Triggers/MissedCall'
import CompletedCall from './Triggers/CompletedCall'
import Birthday from './Triggers/Birthday'
import Anniversary from './Triggers/Anniversary'
import Webhook from './Triggers/Webhook'
import NewTag from './Triggers/NewTag'
import Date from './Triggers/Date'
import CustomDate from './Triggers/CustomDate'

export default {
  components: {
    DirectCall, SmsKeyword, HomeAdvisor, MissedCall, CompletedCall,
    Birthday, Anniversary, Webhook, NewTag, Date, CustomDate,
  },

  props: {
    automation: {
      type: Object,
      default: null,
    },
    type: {
      type: String,
      default: 'create',
    }
  },

  data() {
    return {
      isShowTriggerModal: false,
      selectedTrigger: null,
      selectedGroup: null,
      connectCall: false,
    }
  },

  mounted() {
    this.selectedGroup = this.groups[0];
    this.initData();
  },

  computed: {
    contactFields() {
      return this.$store.getters["auth/contactFields"];
    },

    groups() {
      return this.$store.getters['automation/triggerGroups'] || []
    },

    triggers() {
      let res = [
        ...this.$store.getters['automation/triggers']
      ]
      if (this.contactFields && this.contactFields.length) {
        this.contactFields.forEach(item => {
          if (item.type === 3) { // date type only
            res.push({
              component: 'CustomDate', label: item.name, icon: 'fa fa-calendar-plus-o', id: item.id.toString(), group: 'behavior',
            })
          }
        })
      }
      return res
    },

    selectedTriggers() {
      if (this.selectedGroup)
        return this.triggers.filter(item => item.group === this.selectedGroup.id && (item.visible == undefined || item.visible == 'connectCall' && this.connectCall))
      return []
    }
  },

  methods: {
    initData() {
      this.$store.dispatch('install/fetchAllInstalls').then((res) => {
        this.connectCall = res.data.filter(item => item.status == 'active' && item.active).length
      });
    },

    closeModalTrigger() {
      this.isShowTriggerModal = false
    },
    
    triggerById(id) {
      return this.triggers.find(item => item.id === id)
    },

    showTriggerModal() {
      this.isShowTriggerModal = true
    },

    selectGroup(group) {
      this.selectedGroup = group
    },

    selectTrigger(trigger) {
      this.automation.start_trigger = trigger.id
      this.automation.options = {}
      this.selectedTrigger = trigger
      this.isShowTriggerModal = false
      if (['home-advisor', 'webhook'].includes(this.automation.start_trigger) && !this.automation.webhook)
      {
        this.$store.dispatch('automation/generateWebhookUrl').then((res) => {
          const { full_url, unique_id } = res
          this.automation.webhook = unique_id
          this.automation.options.webhook = full_url
          this.$nextTick(() => {
            this.$refs.startTriggerComponent.onItemClick();
          })
        })
      } else {
        this.automation.webhook = null
        this.automation.options.webhook = null
        this.$nextTick(() => {
          this.$refs.startTriggerComponent.onItemClick();
        })
      }
    },

    deleteStartTrigger() {
      this.selectedTrigger = null
      this.automation.start_trigger = null
      this.automation.webhook = null;
      this.automation.options = {}
    }
  },
    
}
</script>

<style lang="scss" scoped>
.start-action {
  color: #1f2129;
  border: 2px dashed #9298ad;
  min-width: 185px;
  display: inline-block;
  font-weight: 700;
  &.trigger-action {
    background-color: $rb-blue;
    color: #4e5468;
    border: 1px solid $rb-blue-border;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.13);
    border-radius: 5px;
  }
  .start-action-title {
    padding: 16px 12px;
    text-align: center;
    user-select: none;
    cursor: pointer;
  }
  .start-action-wrapper {
    text-align: left;
  }
  .block-controls {
    color: #4b4b5a;
  }
}
</style>
<style lang="scss">
.new-trigger-modal {
  text-align: left;
  .triggers-section {
    display: flex;
    min-height: 300px;
  }
  .modal-body {
    padding: 0;
  }
  .list-groups {
    flex: 0 0 250px;
    background: #4e5468;
    .group-item {
      background: #4e5468;
      color: #FFF;
      padding: 16px 12px;
      font-weight: 700;
      border-bottom: 1px solid #353a4a;
      cursor: pointer;
      &:hover,
      &.active {
        background-color: #353a4a;
      }
      .fa {
        float: right;
      }
    }
  }
  .list-triggers {
    flex: 1 1 auto;
    padding: 0px 15px;
    .trigger-item {
      background-color: #65758A;
      color: #FFF;
      display: flex;
      padding: 8px 5px;
      border-radius: 5px;
      cursor: pointer;
      user-select: none;
      margin-bottom: 10px;
      .icon {
        flex: 0 0 50px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .desc {
        flex: 1 0;
        .name {
          font-weight: 700;
          cursor: pointer;
        }
        p {
          margin-bottom: 0px;
          line-height: 15px;
          font-size: 12px;
        }
      }
    }
  }
}
</style>