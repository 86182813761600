<template>
  <div>
    <div class="start-trigger-label" @click="onItemClick">
      <i :class="trigger.icon"></i> {{ trigger.label }}
      <span v-if="keywordList" class="keywords-span">( {{ keywordList }} )</span>
    </div>

    <vuestic-modal :isOpen="isShowModal" @cancel="closeModal" :okShown="false" :cancelShown="false" :force="true"
      cancelClass="btn btn-danger">
      <span slot="title" class="text-primary font-weight-bold">New Tag Added</span>
      <ValidationObserver v-slot="{ handleSubmit, invalid, dirty }" ref="newCallTriggerForm">
        <form @submit.prevent="handleSubmit(save)" class="newCallTriggerForm">
          <div class="row">
            <div class="col-md-12 mb-4">
              <label class="input-label">Installs</label>
              <multiselect :show-labels="false" v-model="installSelected" :options="installs" :multiple="true"
                :preserve-search="true" placeholder="Select Installs" label="label" track-by="id"
                @input="onSelectChange('install')">
                <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single"
                    v-if="automation.installs && automation.installs.length & !isOpen">{{ automation.installs.length }}
                    options selected</span></template>
              </multiselect>
              <ul v-if="automation.installs" class="selectedInstallWrapper">
                <li v-for="item in automation.installs" :key="item.id">{{ item.label }}</li>
              </ul>
            </div>

            <div class="col-md-12 mb-4">
              <tag-selector label="KEYWORD/TAGS - THE KEYWORD/TAGS BELOW WILL TRIGGER THE AUTOMATION"
                v-model="tagSelected" @input="onSelectChange('tag')"></tag-selector>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-12 d-flex justify-content-center">
              <button class="btn btn-primary" :disabled="invalid || processing" style="min-width: 80px;">
                <atom-spinner v-if="processing" slot="loading" :animation-duration="1500" :size="14" color="#FFF"
                  style="margin: auto;" />
                <span v-else>Save</span>
              </button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </vuestic-modal>
  </div>
</template>
    
<script>
import Vue from 'vue';
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import TagSelector from '../../../../common/TagSelector'

export default {
  components: {
    TagSelector
  },

  props: {
    automation: {
      type: Object,
      default: null,
    },
    blocks: {
      type: Array,
      default: null,
    },
    trigger: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      processing: false,
      isShowModal: false,
      keywords: [],
      loading: false,
      isOpenModalTrigger: false,
      installs: [],
      installSelected: [],
      tagSelected: [],
    }
  },

  computed: {
    keywordList() {
      if (this.tagSelected)
        return this.tagSelected.map(item => item.name).join(', ');
      return ''
    }
  },

  mounted() {
    this.installSelected = this.automation.installs
    this.automation.options = {
      ...{ add_tag: false, tag_in_string: false, stop_on_reply: false },
      ...this.automation.options
    }
    this.tagSelected = this.automation.tags
  },

  methods: {
    initValues() {
      this.installSelected = this.installs.filter(item => {
        return this.automation.installs.find(item1 => item1.id == item.id)
      })
    },

    onSelectChange(type) {
      switch (type) {
        case 'install':
          this.automation.installs = this.installSelected
          break;
        case 'tag':
        default:
          this.automation.tags = this.tagSelected
          break;
      }
    },

    onItemClick() {
      this.showModal();
    },

    save() {
      if (!this.automation.installs || !this.automation.installs.length) {
        Vue.$toast.open({
          message: 'Installs can\'t be empty',
          type: 'error',
          duration: 3000,
        })
        return false;
      }
      this.installSelected = this.automation.installs
      this.isShowModal = false
    },

    showModal() {
      this.loadInstalls();
      this.isShowModal = true
    },

    closeModal() {
      this.isShowModal = false
    },

    loadInstalls() {
      this.loading = false
      this.$store.dispatch('install/fetchAllInstalls').then((res) => {
        this.loading = false
        let tmp = res.data.filter(item => item.status == 'active' && item.active)
        this.installs = tmp.map(item => {
          const phoneNumber = parsePhoneNumberFromString(item.available_number ? item.available_number : '')
          return {
            id: item.id,
            label: (phoneNumber ? phoneNumber.formatNational() + ' : ' : '') + item.website
          }
        })
        this.initValues();
      });
    },
  },
}
</script>
    
<style lang="scss" scoped>
.start-trigger-label {
  color: #fff;
  text-align: center;
  padding: 16px 12px;
  cursor: pointer;
  user-select: none;

  .keywords-span {
    display: block;
    font-size: 13px;
    font-weight: normal;
  }
}

.selectedInstallWrapper {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 10px;

  li {
    padding: 3px 10px;
    color: #ffffff;
    background-color: $rb-blue;
    border-radius: 10px;
    margin: 5px;
    font-weight: bold;
  }
}

.custom-checkbox-wrapper .checkbox-label {
  --primaryColor: #3578c6;
}

.custom-checkbox-wrapper .checkbox-label .checkbox-custom {
  border-color: $text-gray;
  top: 4px;
  height: 17px;
  width: 17px;
}

.custom-checkbox-wrapper .checkbox-label input:checked~.checkbox-custom::after {
  left: 5px;
  top: 0px;
}
</style>