var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"start-trigger-label",on:{"click":_vm.onItemClick}},[_c('i',{class:_vm.trigger.icon}),_vm._v(" "+_vm._s(_vm.trigger.label)+"\n    "),(_vm.keywordList)?_c('span',{staticClass:"keywords-span"},[_vm._v("( "+_vm._s(_vm.keywordList)+" )")]):_vm._e()]),_c('vuestic-modal',{attrs:{"isOpen":_vm.isShowModal,"okShown":false,"cancelShown":false,"force":true,"cancelClass":"btn btn-danger"},on:{"cancel":_vm.closeModal}},[_c('span',{staticClass:"text-primary font-weight-bold",attrs:{"slot":"title"},slot:"title"},[_vm._v("New Tag Added")]),_c('ValidationObserver',{ref:"newCallTriggerForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
var dirty = ref.dirty;
return [_c('form',{staticClass:"newCallTriggerForm",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.save)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 mb-4"},[_c('label',{staticClass:"input-label"},[_vm._v("Installs")]),_c('multiselect',{attrs:{"show-labels":false,"options":_vm.installs,"multiple":true,"preserve-search":true,"placeholder":"Select Installs","label":"label","track-by":"id"},on:{"input":function($event){return _vm.onSelectChange('install')}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var values = ref.values;
var search = ref.search;
var isOpen = ref.isOpen;
return [(_vm.automation.installs && _vm.automation.installs.length & !isOpen)?_c('span',{staticClass:"multiselect__single"},[_vm._v(_vm._s(_vm.automation.installs.length)+"\n                  options selected")]):_vm._e()]}}],null,true),model:{value:(_vm.installSelected),callback:function ($$v) {_vm.installSelected=$$v},expression:"installSelected"}}),(_vm.automation.installs)?_c('ul',{staticClass:"selectedInstallWrapper"},_vm._l((_vm.automation.installs),function(item){return _c('li',{key:item.id},[_vm._v(_vm._s(item.label))])}),0):_vm._e()],1),_c('div',{staticClass:"col-md-12 mb-4"},[_c('tag-selector',{attrs:{"label":"KEYWORD/TAGS - THE KEYWORD/TAGS BELOW WILL TRIGGER THE AUTOMATION"},on:{"input":function($event){return _vm.onSelectChange('tag')}},model:{value:(_vm.tagSelected),callback:function ($$v) {_vm.tagSelected=$$v},expression:"tagSelected"}})],1)]),_c('div',{staticClass:"row mt-3"},[_c('div',{staticClass:"col-md-12 d-flex justify-content-center"},[_c('button',{staticClass:"btn btn-primary",staticStyle:{"min-width":"80px"},attrs:{"disabled":invalid || _vm.processing}},[(_vm.processing)?_c('atom-spinner',{staticStyle:{"margin":"auto"},attrs:{"slot":"loading","animation-duration":1500,"size":14,"color":"#FFF"},slot:"loading"}):_c('span',[_vm._v("Save")])],1)])])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }