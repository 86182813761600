<template>
<div>
  <div class="start-trigger-label" @click="onItemClick">
    <i :class="trigger.icon"></i> {{ trigger.label }}
  </div>

  <vuestic-modal
    :isOpen="isShowModal"
    @cancel="closeModal"
    :okShown="false"
    :cancelShown="false"
    :force="true"
    cancelClass="btn btn-danger"
  >
    <span slot="title" class="text-primary font-weight-bold">Home Advisor Trigger</span>
    <ValidationObserver 
      v-slot="{ handleSubmit, invalid, dirty }"
      ref="homeAdvisorTriggerForm"
    >
      <form @submit.prevent="handleSubmit(save)" class="homeAdvisorTriggerForm">
        <div class="row">
          <div class="col-md-12 mb-4">
            <label class="input-label">Installs</label>
            <multiselect
              :show-labels="false" 
              v-model="installSelected"
              :options="installs"
              :multiple="false"
              :preserve-search="true"
              :allowEmpty="false"
              placeholder="Select Install"
              label="label"
              track-by="id"
              @input="onSelectChange"
            >
            </multiselect>
          </div>
          <div class="col-md-12 mt-4">
            <div class="form-group">
              <label class="control-label">Webhook URL</label>
              <textarea class="code form-control mt-2" rows="2" id="embcod" readonly>{{ automation.options.webhook }}</textarea>
            </div>
          </div>
          <div class="col-md-12 text-center">
              <button
                  type="button"
                  class="btn btn-primary"
                  v-clipboard="automation.options.webhook"
                  v-clipboard:success="onCopy"
                  v-clipboard:error="onError"
              >Copy URL
              </button>
          </div>
          <div class="col-md-12 mt-4" style="font-weight: 600;">
              <p>Email the custom Webhook URL to crmintegrations@homeadvisor.com with the following email: </p>

              <p>“Hi Integration Team.
              My company name is {your company name} and my phone number is {your phone number}.
              Please use the following URL as the Testing Endpoint URL and Production Endpoint URL for your HTTP post feed of new leads for my Home Advisor account.”</p>
              <p>Then Paste the Webhook URL you copied into the email.
              NOTE: They may call to confirm your account information</p>
          </div>
          <div class="col-md-12 mt-2">
            <div class="custom-checkbox-wrapper d-inline-block">
              <label class="checkbox-label">
                <input type="checkbox" v-model="automation.options.stop_on_reply">
                <span class="checkbox-custom"></span>
              </label>
            </div>
            <span class="d-inline-block ml-2" style="cursor: pointer" @click="automation.options.stop_on_reply = !automation.options.stop_on_reply">Stop on Contact Reply <a v-b-tooltip.hover title="When someone replies to a message in the automation the remainder of the messages will be cancelled" class="fa fa-question-circle tooltip-icon" /></span>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-12 d-flex justify-content-center">
            <button class="btn btn-primary" :disabled="invalid || processing" style="min-width: 80px;">
              <atom-spinner v-if="processing" slot="loading" :animation-duration="1500" :size="14" color="#FFF" style="margin: auto;"/>
              <span v-else>Save</span>
            </button>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </vuestic-modal>
</div>
</template>

<script>
import Vue from 'vue';
import { parsePhoneNumberFromString } from 'libphonenumber-js'

export default {
  components: {
  },

  props: {
    automation: {
      type: Object,
      default: null,
    },
    blocks: {
      type: Array,
      default: null,
    },
    trigger: {
      type: Object,
      default: null,
    },
    type: {
      type: String,
      default: 'create',
    }
  },

  data() {
    return {
      processing: false,
      isShowModal: false,
      loading: false,
      isOpenModalTrigger: false,
      installSelected: [],
      installs: [],
    }
  },

  mounted() {
    this.automation.options = {
      ...{ stop_on_reply: false },
      ...this.automation.options
    }
  },

  watch: {
    'automation.installs': {
      handler: function(newValue) {
        if (newValue)
        {
          this.initValues();
        }
      },
      deep: true,
    },
  },

  computed: {
    
  },

  methods: {
    initValues() {
      this.installSelected = this.installs.find(item => {
        return this.automation.installs.find(item1 => item1.id == item.id)
      })
    },

    onSelectChange() {
      this.automation.installs = [this.installSelected]
    },

    onCopy: function (e) {
      Vue.$toast.open({
        message: "Webhook has been copied to clipboard",
        type: "success",
      });
    },
    onError: function (e) {
      this.showToast("Failed to copy Webhook to your clipboard", {
        icon: "fa-exclamation-triangle",
      });
    },

    onItemClick() {
      this.showModal();
    },

    save() {
      if (!this.automation.installs || !this.automation.installs.length)
      {
        Vue.$toast.open({
          message: 'Installs can\'t be empty',
          type: 'error',
          duration: 3000,
        })
        return false;
      }
      this.installSelected = this.automation.installs
      this.isShowModal = false
    },

    showModal() {
      this.loadInstalls();
      this.isShowModal = true
    },
    
    closeModal() {
      this.isShowModal = false
    },

    loadInstalls() {
      this.loading = false
      this.$store.dispatch('install/fetchAllInstalls').then((res) => {
        this.loading = false
        let tmp = res.data.filter(item => item.status == 'active' && item.active)
        this.installs = tmp.map(item => {
          const phoneNumber = parsePhoneNumberFromString(item.available_number ? item.available_number: '')
          return {
            id: item.id,
            label:( phoneNumber ? phoneNumber.formatNational() + ' : ' : '' ) + item.website
          }
        })
        this.initValues();
      });
    },
  },
}
</script>

<style lang="scss" scoped>
  .start-trigger-label {
    color: #fff;
    text-align: center;
    padding: 16px 12px;
    cursor: pointer;
    user-select: none;
  }
  .selectedInstallWrapper {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-top: 10px;
    li {
      padding: 3px 10px;
      color: #ffffff;
      background-color: $rb-blue;
      border-radius: 10px;
      margin: 5px;
      font-weight: bold;
    }
  }
  .custom-checkbox-wrapper .checkbox-label {
    --primaryColor: #3578c6;
  }
  .custom-checkbox-wrapper .checkbox-label .checkbox-custom {
    border-color: $text-gray;
    top: 4px;
    height: 17px;
    width: 17px;
  }

  .custom-checkbox-wrapper .checkbox-label input:checked ~ .checkbox-custom::after {
    left: 5px;
    top: 0px;
  }
</style>