<template>
  <div>
    <div class="start-trigger-label" @click="onItemClick">
      <i :class="trigger.icon"></i> {{ trigger.label }}
    </div>

    <vuestic-modal id="birthday-trigger" :isOpen="isShowModal" @cancel="closeModal" :okShown="false" :cancelShown="false"
      :force="true" cancelClass="btn btn-danger">
      <span slot="title" class="text-primary font-weight-bold">Date</span>
      <ValidationObserver v-slot="{ handleSubmit, invalid }" ref="newCallTriggerForm">
        <form @submit.prevent="handleSubmit(save)" class="newCallTriggerForm">
          <div class="row">
            <div class="col-md-12 mb-4">
              <label class="input-label has-tool-tip">Installs <a v-b-tooltip.hover
                  title="Leave it blank and it will be applied to all installs"
                  class="fa fa-question-circle tooltip-icon" /></label>
              <multiselect :show-labels="false" v-model="installSelected" :options="installs" :multiple="true"
                :preserve-search="true" placeholder="Select Installs" label="label" track-by="id"
                @input="onSelectChange('install')">
                <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single"
                    v-if="automation.installs && automation.installs.length & !isOpen">{{ automation.installs.length }}
                    options selected</span></template>
              </multiselect>
              <ul v-if="automation.installs" class="selectedInstallWrapper">
                <li v-for="item in automation.installs" :key="item.id">{{ item.label }}</li>
              </ul>
            </div>
            <div class="col-md-12 mb-4">
              <tag-selector label="KEYWORD/TAGS - THE KEYWORD/TAGS BELOW WILL TRIGGER THE AUTOMATION"
                tooltip="Leave it blank and it will be applied to all keyword/tags" v-model="tagSelected"
                @input="onSelectChange('tag')"></tag-selector>
            </div>
            <div class="col-md-6">
              <div class="form-group mt-2">
                <label class="control-label">Date</label>
                <vuestic-date-picker v-model="automation.options.date" :config="datePickerConfig"
                  class="form-control mt-2" placeholder="Pick a date" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mt-2">
                <label class="control-label">Time</label>
                <vuestic-date-picker v-model="automation.options.time" :config="timePickerConfig"
                  class="form-control mt-2" placeholder="Pick a time"></vuestic-date-picker>
              </div>
            </div>
            <div class="col-md-12 mt-4">
              <div class="form-group mb-4">
                <label class="control-label mb-2">TimeZone</label>
                <multiselect v-model="automation.options.timezone" :options="timezones" :multiple="false"
                  :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Timezone"
                  label="label" track-by="label" :show-labels="false" :allowEmpty="false">
                </multiselect>
              </div>
              <div class="mb-4">
                <div class="custom-checkbox-wrapper d-inline-block">
                  <label class="checkbox-label">
                    <input type="checkbox" v-model="automation.options.stop_on_reply">
                    <span class="checkbox-custom"></span>
                  </label>
                </div>
                <span class="d-inline-block ml-2" style="cursor: pointer"
                  @click="automation.options.stop_on_reply = !automation.options.stop_on_reply">Stop on Contact Reply <a
                    v-b-tooltip.hover
                    title="When someone replies to a message in the automation the remainder of the messages will be cancelled"
                    class="fa fa-question-circle tooltip-icon" /></span>
              </div>
              <div class="mt-2">
                <strong><span v-b-tooltip.hover title="You can edit this timezone in settings page.">Local Timezone: {{
                  timezone }}</span></strong>
                <div class="localtime">Local Time: {{ localTime }}</div>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-12 d-flex justify-content-center">
              <button class="btn btn-primary" :disabled="invalid || processing" style="min-width: 80px;">
                <atom-spinner v-if="processing" slot="loading" :animation-duration="1500" :size="14" color="#FFF"
                  style="margin: auto;" />
                <span v-else>Save</span>
              </button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </vuestic-modal>
  </div>
</template>
    
<script>
import moment from 'moment-timezone'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import TagSelector from '../../../../common/TagSelector'

export default {
  components: {
    TagSelector
  },

  props: {
    automation: {
      type: Object,
      default: null,
    },
    blocks: {
      type: Array,
      default: null,
    },
    trigger: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      processing: false,
      isShowModal: false,
      keywords: [],
      loading: false,
      datePickerConfig: {
        enableTime: false,
        dateFormat: "Y-m-d",
      },
      timePickerConfig: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "G:i K",
      },
      installs: [],
      installSelected: [],
      tagSelected: [],
    }
  },

  computed: {
    timezones() {
      return this.$store.getters["timeZones"];
    },

    timezone() {
      return `(UTC${moment()
        .format('Z')}) ${moment.tz.guess()}`
    },

    localTime() {
      return moment()
        .format('MMM D, YYYY hh:mm A')
    },
  },

  mounted() {
    let localTimezone = this.timezones.find(
      (item) => item.value === moment.tz.guess()
    )

    this.automation.options = {
      ...{ date: moment().format('YYYY-MM-DD'), time: '09:00 AM', timezone: localTimezone, stop_on_reply: false },
      ...this.automation.options
    }

    this.tagSelected = this.automation.tags
    this.installSelected = this.automation.installs
  },

  methods: {
    initValues() {
      this.installSelected = this.installs.filter(item => {
        return this.automation.installs.find(item1 => item1.id == item.id)
      })
    },

    onSelectChange(type) {
      switch (type) {
        case 'install':
          this.automation.installs = this.installSelected
          break;
        case 'tag':
        default:
          this.automation.tags = this.tagSelected
          break;
      }
    },

    onItemClick() {
      this.showModal();
    },

    save() {
      this.isShowModal = false
      this.convertToUTC()
    },

    showModal() {
      this.loadInstalls();
      this.isShowModal = true
    },

    closeModal() {
      this.isShowModal = false
      this.convertToUTC()
    },

    loadInstalls() {
      this.loading = false
      this.$store.dispatch('install/fetchAllInstalls').then((res) => {
        this.loading = false
        let tmp = res.data.filter(item => item.status == 'active' && item.active)
        this.installs = tmp.map(item => {
          const phoneNumber = parsePhoneNumberFromString(item.available_number ? item.available_number : '')
          return {
            id: item.id,
            label: (phoneNumber ? phoneNumber.formatNational() + ' : ' : '') + item.website
          }
        })
        this.initValues();
      });
    },

    convertToUTC() {
      this.automation.options.send_at = moment
        .tz(this.automation.options.date + " " + this.automation.options.time, "YYYY-MM-DD hh:mm A", this.automation.options.timezone.value)
        .utc()
        .format("YYYY-MM-DD HH:mm:00");
    },

  },
}
</script>
    
<style lang="scss" scoped>
.start-trigger-label {
  color: #fff;
  text-align: center;
  padding: 16px 12px;
  cursor: pointer;
  user-select: none;
}

.selectedInstallWrapper {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 10px;

  li {
    padding: 3px 10px;
    color: #ffffff;
    background-color: $rb-blue;
    border-radius: 10px;
    margin: 5px;
    font-weight: bold;
  }
}

.custom-checkbox-wrapper .checkbox-label {
  --primaryColor: #3578c6;
}

.custom-checkbox-wrapper .checkbox-label .checkbox-custom {
  border-color: $text-gray;
  top: 4px;
  height: 17px;
  width: 17px;
}

.custom-checkbox-wrapper .checkbox-label input:checked~.checkbox-custom::after {
  left: 5px;
  top: 0px;
}

.localtime {
  font-weight: normal;
}
</style>
    
<style lang="scss">
#birthday-trigger {
  .multiselect {
    margin-top: 8px;
    font-weight: normal;

    .multiselect__input {
      border: none;
      padding: 0px 0px;
    }
  }
}
</style>